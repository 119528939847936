import React, { useState, useEffect } from "react";

function Modal({
  id,
  top,
  left,
  onClose,
  noShowPeriod = 0,
  showAgainOption = true,
  imgSrc,
  imgAlt,
  closeImgSrc,
  noShowImgSrc,
}) {
  const [noShowChecked, setNoShowChecked] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false); // 초기 로딩 상태 추가

  // 로컬 스토리지에서 모달 숨김 여부 확인
  useEffect(() => {
    const noShowUntil = localStorage.getItem(`noShowUntil_${id}`);
    if (noShowUntil && new Date(noShowUntil) > new Date()) {
      onClose(); // 기한이 남아 있으면 모달을 닫음
    }
    setIsLoaded(true); // 로딩 완료 상태 설정
  }, [id, onClose]);

  // "다시 보지 않기" 클릭 시 로컬 스토리지에 날짜 설정 (일주일 고정)
  const handleNoShow = () => {
    const noShowDate = new Date();
    noShowDate.setDate(noShowDate.getDate() + 7); // 7일 설정
    localStorage.setItem(`noShowUntil_${id}`, noShowDate.toISOString());
    onClose();
  };

  // ✅ 로딩이 완료된 후에만 모달을 렌더링
  if (!isLoaded) {
    return null;
  }

  return (
    <div
      className={`modal-content modal${id}`}
      style={{ top: top, left: left }}
    >
      <img src={imgSrc} alt={imgAlt} className="modal-image" />

      {/* "다시 보지 않기" 이미지 */}
      <div className="btns">
        <img
          src={noShowImgSrc}
          alt="다시 보지 않기 버튼"
          className="no-show-button"
          onClick={handleNoShow}
        />
        {/* 닫기 버튼 이미지 */}
        <img
          src={closeImgSrc}
          alt="닫기"
          className="close-button"
          onClick={onClose}
        />
      </div>
    </div>
  );
}

export default Modal;

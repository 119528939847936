import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
function ImgSlide() {
  const width = useWindowWidth();
  const [shared, setShared] = useState(385.5);

  useEffect(() => {
    if (width < 576) {
      setShared(150);
    } else {
      setShared(230);
    }
  }, [width]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: width / shared,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
  };

  const images = [];
  for (let i = 1; i <= 10; i++) {
    images.push(
      `${process.env.PUBLIC_URL}/Images/5/img_${String(i).padStart(2, "0")}.png`
    );
  }

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {images.map((imgSrc, index) => (
          <div key={index} className="FouthCard">
            <img loading="lazy" src={imgSrc} alt={`img_${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    // cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
}

function HomeFour() {
  const today = new Date();
  let currentMonth = today.getMonth(); // 변경: let 사용
  let currentYearLastTwoDigits = today.getFullYear() % 100; // 변경: let 사용

  // 1월일 경우 전년도 12월로 수정
  if (currentMonth === 0) {
    currentMonth = 12;
    currentYearLastTwoDigits -= 1;
  }

  const [count, setCount] = useState(0);
  const [moneyCount, setMoneyCount] = useState(0);
  const [startAnimation, setStartAnimation] = useState(false);
  const screenWidth = useWindowWidth();

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      const screenWidth = window.innerWidth;
      // 모바일과 데스크톱에서 다른 스크롤 위치 설정
      if (
        (screenWidth <= 600 && position > 3000) ||
        (screenWidth > 600 && position > 4300)
      ) {
        if (!startAnimation) {
          setStartAnimation(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [startAnimation]);

  // // 숫자 증가 애니메이션
  // useEffect(() => {
  //   if (startAnimation) {
  //     let start = 0;
  //     const timer = setInterval(() => {
  //       start += 5;
  //       setCount(start);
  //       if (start >= 735) {
  //         clearInterval(timer);
  //       }
  //     }, 15);
  //     return () => clearInterval(timer);
  //   }
  // }, [startAnimation]);

  // 숫자 증가 애니메이션
  useEffect(() => {
    if (startAnimation) {
      let start = 0;
      let startMoney = 0;
      const maxCount = 735;
      const maxMoney = 2500;

      const timer = setInterval(() => {
        start += 5;
        startMoney += (maxMoney / maxCount) * 5;
        setCount(start);
        setMoneyCount(Math.min(startMoney, maxMoney).toFixed(0));

        if (start >= maxCount) {
          clearInterval(timer);
        }
      }, 15);

      return () => clearInterval(timer);
    }
  }, [startAnimation]);

  return (
    <section id="workSection">
      <ImgSlide />

      <div className="sectionInner">
        <div className="sevenHeader">
          <h6 className="sectionSubTitle">최근 현황</h6>
          <h2 className="sectionTitle">
            이번 달도 치과 마케팅만,
            <br className="mobile-br" /> <span>치과에 진심인</span> 퍼널스
            입니다.
          </h2>
          {screenWidth <= 1120 && (
            <img
              src={process.env.PUBLIC_URL + "/Images/2/thridnewimg.png"}
              alt="thridnewimg"
              className="thridnewimgFour"
            />
          )}
          <h3>
            <span className="fontEn">Performance Marketing</span>
            <br />
            {currentYearLastTwoDigits}년 {currentMonth}월 퍼널스 파트너 치과
            평균 지표
          </h3>
        </div>
        <ul className="boxWrap">
          <li>
            <div className="navyBox">
              <h4>- 평균 광고비 -</h4>
              <h3>{parseInt(moneyCount).toLocaleString()}만원</h3>
              <h6>*최소 500~최대 7,000만원</h6>
            </div>
            {screenWidth >= 1120 && (
              <div className="whiteBox">
                <h3>
                  <span>1</span>퍼포먼스에 집중
                </h3>
                <div>
                  치과의 하드웨어를 고려한 <br />
                  퍼포먼스 중심 예산을 제안드립니다.
                </div>
              </div>
            )}
          </li>
          <li>
            <div className="navyBox">
              <h4>- 성과 BEST -</h4>
              <h3>
                DB마케팅
                <br />
                플레이스 상위노출
              </h3>
            </div>
            {screenWidth >= 1120 && (
              <div className="whiteBox">
                <h3>
                  <span>2</span>매체 우선순위
                </h3>
                <div>
                  퍼널스가 가장 잘하며 <br />
                  효율을 극대화 시키는 매체입니다
                </div>
              </div>
            )}
          </li>
          <li>
            <div className="navyBox">
              <h4>- 평균 ROAS -</h4>
              <h3>{count}%</h3>
              <h6>*실제 데이터 기반 작성</h6>
              {screenWidth >= 1120 && (
                <p className="etcText">
                  *ROAS(Return On Ad Spend)= 광고로 인한 수익률
                </p>
              )}
            </div>
            {screenWidth >= 1120 && (
              <div className="whiteBox">
                <h3>
                  <span>3</span>최종 광고 수익률
                </h3>
                <div>
                  매출이 오르지 않으면
                  <br />
                  광고비는 환불해드립니다
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>
    </section>
  );
}

export default HomeFour;
